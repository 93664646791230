const colors = {
	primary: '#DB0C1C',
	secondary: '#e8eaed',
	lightred: '#be0027',
	red: '#DB0C1C',
	dark: '#2B3037',
	light: '#F6F6F7',
	lightgrey: '#F1EFEC',
	white: '#fff',
	grey:'#c4c4c4',
	green: '#96CA2D'
};

export default colors;