import React from 'react';
import ReactDOM from 'react-dom';

import {AnimatePresence, motion} from 'framer-motion';
import settings from '@clientSettings';
import { loaderLogo, loaderOverlay } from './styles';

const Loader = ({loading}) => 
	ReactDOM.createPortal(
		<AnimatePresence>
			{loading &&
				<>
					<motion.div
						key="loader-container"
						initial={{opacity: 0}}
						animate={{opacity: 1}}
						exit={{opacity: 0}}
						transition={{duration: 0.2}}
						css={loaderOverlay}
					/>
					<motion.div
						key='loader-logo'
						animate={{
							opacity: [1, 0.8, 1, 0.8, 1]
						}}
						transition={{
							duration: 2,
							ease: 'easeInOut',
							times: [0, 0.2, 0.5, 0.8, 1],
							repeat: Infinity,
							repeatDelay: 1
						}}
						css={loaderLogo}
					>
						{/* <img src={Logo} width='200' alt='Logo' loading='eager' /> */}
						<h3 css={{color: settings.colors.blue, fontSize: '30px'}}>
							Lädt ...	
						</h3>
					
					</motion.div>
				</>
			}
		</AnimatePresence>,
		document.body
	);
  
export default Loader;
