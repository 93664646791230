import {ApolloClient, InMemoryCache } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';

const token = Cookies.get('st');

export const client = new ApolloClient({

	uri: 'https://pg-app-mvx9tbt2yit00ef2pzlktzg3k81djj.scalabl.cloud/graphql/',
	headers: {
		'X-Parse-Application-Id': process.env.GATSBY_SASHIDO_ACCESS_TOKEN,
		'X-Parse-Master-Key': process.env.GATSBY_SASHIDO_MASTER_KEY,
		'X-Parse-Session-Token': token
	},
	cache: new InMemoryCache(),
	fetch
 
});

