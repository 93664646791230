const loaderOverlay = {
	position: 'fixed',
	height: '100vh',
	width: '100vw',
	backgroundColor: 'rgba(0,0,0, 0.15)',
	zIndex: 12,
	top: 0,
	left: 0
};

const loaderLogo ={
	position: 'fixed', 
	transform: 'translate(-50%, -50%)', 
	top: '50%', 
	left: '50%',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '30px',
	zIndex: 13
};

export {loaderOverlay, loaderLogo};