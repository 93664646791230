import React, { useCallback, useEffect , useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { navigate } from 'gatsby';
import Loader from '@clientComponents/surfaces/Loader/Loader';
import UserContext from './context';

const GET_SESSION = gql`
query session($sessionToken: String) {
  objects {
    find_Session(
      where: { sessionToken: { _eq: $sessionToken } }
    ) {
      results {
        user {
          objectId
          username
          email
          rolle
          portrait
          adresse1
          adresse2
          iban
          bic
          abteilung {
            objectId
            name
			fachabteilung {
				titel
				objectId
				name
			}
          }
          bild {
            url
          }
        }
      }
    }
  }
}`;

const LOGOUT_SESSION = gql`
  mutation logOut {
    users {
      logOut
    }
  }
`;

const LOGIN_USER = gql` 
  mutation logIn($password: String!, $username: String!) {
    users {
      logIn(username: $username, password: $password){
        username
        email
        sessionToken
      }
    }
  }
`;

const UserContextProvider = ({children}) => {
	const [storedUser, setUser] = useState();
	
	// get user
	const [getSession] = useLazyQuery(GET_SESSION, {
		fetchPolicy: 'no-cache'
	});

	const sessionHandler = (token) => {
		getSession({variables: { sessionToken: token }}).then(results => {
			setUser(results?.data?.objects?.find_Session?.results[0]?.user);
		});
	};

	useEffect(() => {
		const token = Cookies.get('st');
		if (token && !storedUser) {
			sessionHandler(token);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const afterLoginHandler = useCallback(async (user) => {
		Cookies.set('st', user.sessionToken, { expires: 90});
		await getSession({variables: { sessionToken: user.sessionToken }}).then(results => {
			setUser(results.data.objects.find_Session.results[0].user);
			setTimeout(() => {
				navigate('/app/profile');
			}, [300]);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// login

	const [userLogin, { loading: loginLoading }] = useMutation(LOGIN_USER,
		{
			fetchPolicy: 'no-cache',
			onError: loginError => { 
				if (loginError.message === 'Invalid username/password.') {
					window.alert('Falsche E-Mail / Passwort Kombination');
				}
			},
			onCompleted: data => afterLoginHandler(data.users.logIn)
		}
	);


	// logout
	const [userLogout] = useMutation(LOGOUT_SESSION, {
		fetchPolicy: 'no-cache',
		onCompleted: () => {
			Cookies.remove('st');
			setUser(null);
			navigate('/login');
		}
	});

	const userContextObject = useMemo(() => ({
		storedUser,
		logout: () => {
			const token = Cookies.get('st');
			userLogout(token);
		},
		login: (object) => {
			userLogin(object);
		},
		isLoggedIn: () => {
			if (storedUser) return true;
			return false;
		}
	}), [userLogin, userLogout, storedUser]);
      
	return (
		<UserContext.Provider
			value={userContextObject}
		>
			{typeof window !== 'undefined' && <Loader loading={loginLoading} />}
			{children}  
		</UserContext.Provider>
	);
};

UserContextProvider.propTypes = {
	children: PropTypes.node.isRequired
};

export default UserContextProvider;
