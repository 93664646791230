import React  from 'react';
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';
import UserContextProvider from './src/provider/userContext';

export const wrapRootElement = ({ element, props }) => (
    <ApolloProvider client={client}>
        <UserContextProvider>
            {element}
        </UserContextProvider>
    </ApolloProvider>
);