import {colors, componentStyles, mq, functions} from './definitions';

const settings = {
	colors, 
	mq,
	padding: componentStyles.padding,
	fontSize: componentStyles.fontSize,
	borderRadius: componentStyles.borderRadius,
	sizes: componentStyles.sizes,
	shadows: componentStyles.shadows,
	functions:  {
		dateHandler: functions.dateHandler,
		slugify: functions.slugify,
		logoHandler: functions.logoHandler,
		dateToObject: functions.dateToString,
		teamclassHandler: functions.spielklasseHandler
	}
};

export default settings;