exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-datenschutzerklaerung-jsx": () => import("./../../../src/pages/datenschutzerklaerung.jsx" /* webpackChunkName: "component---src-pages-datenschutzerklaerung-jsx" */),
  "component---src-pages-impressum-jsx": () => import("./../../../src/pages/impressum.jsx" /* webpackChunkName: "component---src-pages-impressum-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-medien-berichte-jsx": () => import("./../../../src/pages/medien/berichte.jsx" /* webpackChunkName: "component---src-pages-medien-berichte-jsx" */),
  "component---src-pages-medien-downloads-jsx": () => import("./../../../src/pages/medien/downloads.jsx" /* webpackChunkName: "component---src-pages-medien-downloads-jsx" */),
  "component---src-pages-medien-jsx": () => import("./../../../src/pages/medien.jsx" /* webpackChunkName: "component---src-pages-medien-jsx" */),
  "component---src-pages-medien-veroeffentlichungen-jsx": () => import("./../../../src/pages/medien/veroeffentlichungen.jsx" /* webpackChunkName: "component---src-pages-medien-veroeffentlichungen-jsx" */),
  "component---src-pages-mitgliedschaft-jsx": () => import("./../../../src/pages/mitgliedschaft.jsx" /* webpackChunkName: "component---src-pages-mitgliedschaft-jsx" */),
  "component---src-pages-sportarten-jsx": () => import("./../../../src/pages/sportarten.jsx" /* webpackChunkName: "component---src-pages-sportarten-jsx" */),
  "component---src-pages-sportfest-2022-jsx": () => import("./../../../src/pages/sportfest-2022.jsx" /* webpackChunkName: "component---src-pages-sportfest-2022-jsx" */),
  "component---src-pages-verein-chronik-jsx": () => import("./../../../src/pages/verein/chronik.jsx" /* webpackChunkName: "component---src-pages-verein-chronik-jsx" */),
  "component---src-pages-verein-hallen-jsx": () => import("./../../../src/pages/verein/hallen.jsx" /* webpackChunkName: "component---src-pages-verein-hallen-jsx" */),
  "component---src-pages-verein-praevention-jsx": () => import("./../../../src/pages/verein/praevention.jsx" /* webpackChunkName: "component---src-pages-verein-praevention-jsx" */),
  "component---src-pages-verein-satzung-jsx": () => import("./../../../src/pages/verein/satzung.jsx" /* webpackChunkName: "component---src-pages-verein-satzung-jsx" */),
  "component---src-pages-verein-termine-jsx": () => import("./../../../src/pages/verein/termine.jsx" /* webpackChunkName: "component---src-pages-verein-termine-jsx" */),
  "component---src-pages-verein-vorstand-jsx": () => import("./../../../src/pages/verein/vorstand.jsx" /* webpackChunkName: "component---src-pages-verein-vorstand-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/BlogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-fachabteilung-template-jsx": () => import("./../../../src/templates/FachabteilungTemplate.jsx" /* webpackChunkName: "component---src-templates-fachabteilung-template-jsx" */),
  "component---src-templates-sportartenseite-template-jsx": () => import("./../../../src/templates/SportartenseiteTemplate.jsx" /* webpackChunkName: "component---src-templates-sportartenseite-template-jsx" */)
}

